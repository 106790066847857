import { TColumnLabeledTextBackgroundSection } from './ColumnLabeledTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useColumnLabeledTextBackgroundData = ({
  primary,
  items,
}: TColumnLabeledTextBackgroundSection) => {
  const {
    solution_column_labeled_text_background_title: title,
    solution_column_labeled_text_background_description: description,
  } = primary;

  const columnImages = items.reduce(
    (
      acm,
      {
        solution_column_labeled_text_background_column_image: image,
        solution_column_labeled_text_background_column_label: label,
      }
    ) => {
      if (!image || !image.gatsbyImageData) return acm;

      const { gatsbyImageData, alt } = image;
      return [...acm, { image: gatsbyImageData, alt: alt ?? '', label }];
    },
    []
  );

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    columnImages,
  };
};
