import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import { useHeaderTextData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type THeaderTextSection =
  Normalize<Queries.SolutionDesktopHeaderTextData_PrismicSolutionDataDesktopSolutionHeaderText_Fragment>;

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    position: relative;
    row-gap: 7.75rem;
    align-content: center;
    justify-content: flex-start;
    background-color: ${colors.white};
    padding: 6rem 0 6rem 19.5rem;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      background-color: #9a9a9a;
      padding: 0 6rem 0 1.5rem;
      text-align: left;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.black};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const HeaderTextSection: React.FC<THeaderTextSection> = sliceData => {
  const { title, description } = useHeaderTextData(sliceData);

  return (
    <Container>
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
      <Description render={description} />
    </Container>
  );
};

export const desktopHeaderTextFragment = graphql`
  fragment SolutionDesktopHeaderTextData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionHeaderText {
      slice_type

      primary {
        solution_header_text_title {
          richText
        }
        solution_header_text_description {
          richText
        }
      }
    }
  }
`;

export default HeaderTextSection;
