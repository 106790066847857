import { TRightPaddedDividerTextBackgroundSection } from './RightPaddedDividerTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useTextDividerImageData = ({ primary }: TRightPaddedDividerTextBackgroundSection) => {
  const {
    solution_right_padded_divider_text_background_image: backgroundImage,
    solution_right_padded_divider_text_background_title: title,
    solution_right_padded_divider_text_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
