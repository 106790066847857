import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { withSolutionProvider } from './SolutionContext';
import { useSolutionData } from './hooks';
import HeroSection from './HeroSection';
import DesktopContent from './DesktopContent';
import Content from './Content';

import Faq from 'components/Faq';
import Layout from 'components/Layout';

const Solution: React.FC<Omit<PageProps, 'data'>> = () => {
  const { layoutProps, data, ids } = useSolutionData();

  return (
    <Layout {...layoutProps}>
      <HeroSection id={ids.hero} />
      <DesktopContent />
      <Content />
      <Faq id={ids.faq} data={data} />
    </Layout>
  );
};

export const solutionFragment = graphql`
  fragment SolutionData on PrismicSolution {
    ...SolutionHeroData
    ...SolutionDesktopContentData
    ...SolutionContentData
    ...FaqData
  }

  fragment SolutionLayoutData on PrismicSolution {
    lang
    alternate_languages {
      document {
        ... on PrismicSolution {
          lang
          url
        }
      }
    }

    data {
      compact_title
      is_dark
    }
  }
`;

export default withSolutionProvider(Solution);
