import { RichTextBlock } from 'prismic-reactjs';
import React, { useMemo, useRef } from 'react';

import { TCircularExpansionTextBackgroundSection } from './CircularExpansionTextBackgroundSection';

import useMaskCircle from 'utils/animations/useMaskCircle';
import { useBounceTextEnterAnimation } from 'utils/animations/useBounceTextEnterAnimation';
import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

const useCircularExpansionTextBackgroundAnimation = () => {
  const titleRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

  const titleRefValues = Object.values(titleRefs.current);

  const { containerRef, contentRef } = useMaskCircle(titleRefValues);

  useBounceTextEnterAnimation(containerRef, titleRefValues);

  const getTitleRef: (key: string) => React.LegacyRef<HTMLDivElement> = key => element => {
    if (!titleRefs.current[key]) {
      titleRefs.current[key] = { current: element };
    }
  };

  return { containerRef, contentRef, getTitleRef };
};

export const useCircularExpansionTextBackgroundData = ({
  primary,
}: TCircularExpansionTextBackgroundSection) => {
  const {
    solution_circular_expansion_text_background_image: backgroundImage,
    solution_circular_expansion_text_background_title,
    solution_circular_expansion_text_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const title: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_circular_expansion_text_background_title, '')
      ),
    [solution_circular_expansion_text_background_title]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    ...useCircularExpansionTextBackgroundAnimation(),
  };
};
