import { TDualToneCenteredTextSection } from './DualToneCenteredTextSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useDualToneCenteredTextData = ({ primary }: TDualToneCenteredTextSection) => {
  const {
    solution_dual_tone_centered_text_title: title,
    solution_dual_tone_centered_text_description: description,
  } = primary;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
  };
};
