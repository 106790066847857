import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useHeaderBackgroundData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type THeaderBackgroundSection =
  Normalize<Queries.SolutionDesktopHeaderBackgroundData_PrismicSolutionDataDesktopSolutionHeaderBackground_Fragment>;

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  position: relative;
  align-content: flex-start;
  justify-content: flex-start;
  padding-top: 40.625rem;
  height: 139.625rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: ${colors.white}12;
    backdrop-filter: blur(1.25rem);

    & > h2 {
      padding: 10rem 5.5rem 7.5rem;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.primary};
      font-size: 11.125rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const HeaderBackgroundSection: React.FC<THeaderBackgroundSection> = sliceData => {
  const { backgroundImage, title } = useHeaderBackgroundData(sliceData);

  return (
    <Container>
      {backgroundImage ? <BackgroundImage {...backgroundImage} /> : null}
      <Title render={title} />
    </Container>
  );
};

export const desktopHeaderBackgroundFragment = graphql`
  fragment SolutionDesktopHeaderBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionHeaderBackground {
      slice_type

      primary {
        solution_header_background_image {
          gatsbyImageData
          alt
        }
        solution_header_background_title {
          richText
        }
      }
    }
  }
`;

export default HeaderBackgroundSection;
