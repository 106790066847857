import { RichTextBlock } from 'prismic-reactjs';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';

import { TQuadBlockTextBackgroundSection } from './QuadBlockTextBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useQuadBlockTextBackgroundData = ({ primary }: TQuadBlockTextBackgroundSection) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          translateY: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          translateY: '-24.9%',
        }
      );
    });

    return () => context.revert();
  }, []);

  const {
    solution_quad_block_text_background_image: backgroundImage,
    solution_quad_block_text_background_primary_title: primaryTitle,
    solution_quad_block_text_background_primary_description: primaryDescription,
    solution_quad_block_text_background_secondary_title,
    solution_quad_block_text_background_secondary_description: secondaryDescription,
    solution_quad_block_text_background_tertiary_title: tertiaryTitle,
    solution_quad_block_text_background_tertiary_description: tertiaryDescription,
    solution_quad_block_text_background_quarternary_title,
    solution_quad_block_text_background_quarternary_description: quarternaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const secondaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_quad_block_text_background_secondary_title, '')
      ),
    [solution_quad_block_text_background_secondary_title]
  );
  const quarternaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_quad_block_text_background_quarternary_title, '')
      ),
    [solution_quad_block_text_background_quarternary_title]
  );

  return {
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryTitle,
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    tertiaryTitle: richTextWithPlaceholder(tertiaryTitle, ''),
    tertiaryDescription: richTextWithPlaceholder(tertiaryDescription, ''),
    quarternaryTitle,
    quarternaryDescription: richTextWithPlaceholder(quarternaryDescription, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    backgroundRef,
    containerRef,
  };
};
