import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface ISecondaryContent {
  title: RichTextBlock[][];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 9rem;
  align-content: flex-end;
  z-index: 1;
  padding: 0 0 4rem 18.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-left: 4rem;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      background-color: ${colors.white}52;
      padding-right: 6rem;
      text-align: left;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.black};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    background-color: #5b5b5b52;

    & > p {
      padding: 4rem 3rem;
      text-align: left;
      line-height: 1.2;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ title, description }) => {
  return (
    <Container>
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
      <Description render={description} />
    </Container>
  );
};

export const desktopQuadBlockTextBackgroundSecondaryContentFragment = graphql`
  fragment SolutionDesktopQuadBlockTextBackgroundSecondaryContentData on PrismicSolutionDataDesktopSolutionQuadBlockTextBackground {
    primary {
      solution_quad_block_text_background_secondary_title {
        richText
      }
      solution_quad_block_text_background_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
