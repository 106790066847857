import { TDualBlockTextSingleBackgroundSection } from './DualBlockTextSingleBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useDualBlockTextBackgroundData = ({
  primary,
}: TDualBlockTextSingleBackgroundSection) => {
  const {
    solution_dual_block_text_single_background_image: backgroundImage,
    solution_dual_block_text_single_background_primary_title: primaryTitle,
    solution_dual_block_text_single_background_primary_description: primaryDescription,
    solution_dual_block_text_single_background_secondary_title: secondaryTitle,
    solution_dual_block_text_single_background_secondary_description: secondaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
  };
};
