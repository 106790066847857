import { TClockTextBackgroundSection } from './ClockTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useClockTextBackgroundData = ({ primary, items }: TClockTextBackgroundSection) => {
  const { solution_clock_text_background_title: title } = primary;

  const clocks = items.reduce(
    (
      acm,
      {
        solution_clock_text_background_clock_image: image,
        solution_clock_text_background_clock_label: label,
      }
    ) => {
      if (!image || !image.gatsbyImageData || !label) return acm;

      const { gatsbyImageData, alt } = image;
      return [...acm, { image: gatsbyImageData, alt: alt ?? label, label }];
    },
    []
  );

  return {
    title: richTextWithPlaceholder(title, ''),
    clocks,
  };
};
