import React from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { graphql } from 'gatsby';

import { useCenteredTextData } from './hooks';
import { VARIANT } from './constants';

import { filterProps } from 'utils/helpers';
import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TCenteredTextSection =
  Normalize<Queries.SolutionDesktopCenteredTextData_PrismicSolutionDataDesktopSolutionCenteredText_Fragment>;

enum CENTERED_TEXT_VARIABLES {
  backgroundColor = '--background-color',
  textColor = '--text-color',
}

const generateVariantColorMap = ({ colors }: Theme) => ({
  [VARIANT.LIGHT]: {
    [CENTERED_TEXT_VARIABLES.textColor]: colors.black,
    [CENTERED_TEXT_VARIABLES.backgroundColor]: colors.offWhite,
  },
  [VARIANT.DARK]: {
    [CENTERED_TEXT_VARIABLES.textColor]: `${colors.white}F5`,
    [CENTERED_TEXT_VARIABLES.backgroundColor]: colors.backgroundDark,
  },
  [VARIANT.LIGHT_DARK]: {
    [CENTERED_TEXT_VARIABLES.textColor]: colors.white,
    [CENTERED_TEXT_VARIABLES.backgroundColor]: colors.backgroundLightDark,
  },
});

const Container = styled('div', filterProps('variant'))<{ variant: VARIANT }>`
  ${({ theme, variant }) => {
    const variantColorMap = generateVariantColorMap(theme)[variant];
    return css`
      ${CENTERED_TEXT_VARIABLES.textColor}: ${variantColorMap[CENTERED_TEXT_VARIABLES.textColor]};

      display: grid;
      position: relative;
      align-content: center;
      justify-items: center;
      background-color: ${variantColorMap[CENTERED_TEXT_VARIABLES.backgroundColor]};
      padding: 12rem 0;
    `;
  }}
`;

const Description = styled(RichText)`
  ${({ theme: { tp } }) => css`
    display: flex;
    position: relative;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: var(${CENTERED_TEXT_VARIABLES.textColor});
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const CenteredTextSection: React.FC<TCenteredTextSection> = sliceData => {
  const { variant, description } = useCenteredTextData(sliceData);

  return (
    <Container variant={variant}>
      <Description render={description} />
    </Container>
  );
};

export const desktopCenteredTextFragment = graphql`
  fragment SolutionDesktopCenteredTextData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionCenteredText {
      slice_type

      primary {
        solution_centered_text_variant
        solution_centered_text_description {
          richText
        }
      }
    }
  }
`;

export default CenteredTextSection;
