import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useTextLongBackgroundData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TTextLongBackgroundSection =
  Normalize<Queries.SolutionDesktopTextLongBackgroundData_PrismicSolutionDataDesktopSolutionTextLongBackground_Fragment>;

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  position: relative;
  row-gap: 10.25rem;
  align-content: flex-start;
  padding: 23.375rem 0 0 12.5rem;
  height: 266.625rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      background-color: ${colors.primary}99;
      padding: 1rem 4rem 1rem 1.5rem;
      text-align: left;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.black};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    z-index: 1;
    background-color: ${colors.white}66;
    backdrop-filter: blur(2.6875rem);
    width: fit-content;

    & > p {
      padding: 1.5rem 2rem;
      text-align: left;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const TextLongBackgroundSection: React.FC<TTextLongBackgroundSection> = sliceData => {
  const { backgroundImage, title, description } = useTextLongBackgroundData(sliceData);

  return (
    <Container>
      {backgroundImage ? <BackgroundImage {...backgroundImage} /> : null}
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
      <Description render={description} />
    </Container>
  );
};

export const desktopTextLongBackgroundFragment = graphql`
  fragment SolutionDesktopTextLongBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionTextLongBackground {
      slice_type

      primary {
        solution_text_long_background_image {
          gatsbyImageData
          alt
        }
        solution_text_long_background_title {
          richText
        }
        solution_text_long_background_description {
          richText
        }
      }
    }
  }
`;

export default TextLongBackgroundSection;
