import { TBottomCenteredTextBackgroundSection } from './BottomCenteredTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useBottomCenteredTextBackgroundData = ({
  primary,
}: TBottomCenteredTextBackgroundSection) => {
  const {
    solution_bottom_centered_text_background_background_image: backgroundImage,
    solution_bottom_centered_text_background_title: title,
    solution_bottom_centered_text_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
