import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useColumnHeaderBackgroundData } from './hooks';

import { filterProps } from 'utils/helpers';
import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TColumnHeaderBackgroundSection =
  Normalize<Queries.SolutionDesktopColumnHeaderBackgroundData_PrismicSolutionDataDesktopSolutionColumnHeaderBackground_Fragment>;

const Container = styled('div', filterProps('columns'))<{
  columns: number;
}>`
  ${({ theme: { colors }, columns }) => css`
    display: grid;
    position: relative;
    grid-template-columns: repeat(${columns}, calc(100% / ${columns}));
    align-content: flex-start;
    justify-content: center;
    margin-bottom: -1px;
    background-color: ${colors.black};
    overflow: hidden;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    justify-content: center;

    & > h2 {
      background-color: ${colors.black}4D;
      padding: 0 6rem;
      text-align: center;
      text-shadow: 0 0.5rem 0.5rem ${colors.black};
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const ColumnHeaderBackgroundSection: React.FC<TColumnHeaderBackgroundSection> = sliceData => {
  const { columnImages, title } = useColumnHeaderBackgroundData(sliceData);

  return (
    <Container columns={columnImages.length}>
      {columnImages.length
        ? columnImages.map(({ image, alt }, index) => (
            <GatsbyImage key={`${alt}${index}`} image={image} alt={alt} />
          ))
        : null}
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
    </Container>
  );
};

export const desktopColumnHeaderBackgroundFragment = graphql`
  fragment SolutionDesktopColumnHeaderBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionColumnHeaderBackground {
      slice_type

      primary {
        solution_column_header_background_title {
          richText
        }
      }

      items {
        solution_column_header_background_column_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default ColumnHeaderBackgroundSection;
