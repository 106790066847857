import { TSingleToneDividerTextSection } from './SingleToneDividerTextSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useSingleToneDividerTextData = ({ primary }: TSingleToneDividerTextSection) => {
  const {
    solution_single_tone_divider_text_is_dark: isDark,
    solution_single_tone_divider_text_title: title,
    solution_single_tone_divider_text_description: description,
  } = primary;

  return {
    isDark,
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
  };
};
