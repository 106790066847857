import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useGridImagesData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TGridImagesSection =
  Normalize<Queries.SolutionDesktopGridImagesData_PrismicSolutionDataDesktopSolutionGridImages_Fragment>;

export enum GRID_IMAGES_AREAS {
  TITLE = 'title',
}

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    position: relative;
    grid-template-areas:
      '. . . .'
      '. ${GRID_IMAGES_AREAS.TITLE} ${GRID_IMAGES_AREAS.TITLE} .'
      '. . . .';
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(4, 1fr);
    align-content: flex-end;
    background-color: ${colors.backgroundDark};
    height: 140.625rem;
  `}
`;

const GridImage = styled(GatsbyImage)`
  ${({ theme: { colors } }) => css`
    display: grid;
    background-color: ${colors.black};
    width: 100%;
    height: 100%;
    overflow: hidden;
  `}
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    grid-area: ${GRID_IMAGES_AREAS.TITLE};
    align-items: center;
    justify-content: center;

    & > h2 {
      text-align: center;
      text-shadow: 0 0.5rem 0.5rem ${colors.black}40;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white}E6;
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const GridImagesSection: React.FC<TGridImagesSection> = sliceData => {
  const { title, gridImages } = useGridImagesData(sliceData);

  return (
    <Container>
      {gridImages?.map(({ image, alt }, index) => (
        <GridImage key={`${alt}${index}`} image={image} alt={alt} />
      )) ?? null}
      <Title render={title} />
    </Container>
  );
};

export const desktopGridImagesFragment = graphql`
  fragment SolutionDesktopGridImagesData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionGridImages {
      slice_type

      primary {
        solution_grid_images_title {
          richText
        }
      }

      items {
        solution_grid_images_grid_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default GridImagesSection;
