import React, { useMemo, useRef } from 'react';
import { RichTextBlock } from 'prismic-reactjs';

import { TCircularExpansionIndustriesSection } from './CircularExpansionIndustriesSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';
import useMaskCircle from 'utils/animations/useMaskCircle';
import { useBounceTextEnterAnimation } from 'utils/animations/useBounceTextEnterAnimation';

const useTextBackgroundAnimation = () => {
  const titleRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

  const titleRefValues = Object.values(titleRefs.current);

  const { containerRef, contentRef } = useMaskCircle(titleRefValues);

  useBounceTextEnterAnimation(containerRef, titleRefValues);

  const getTitleRef: (key: string) => React.LegacyRef<HTMLDivElement> = key => element => {
    if (!titleRefs.current[key]) {
      titleRefs.current[key] = { current: element };
    }
  };

  return { containerRef, contentRef, getTitleRef };
};

export const useTextBackgroundData = ({ primary, items }: TCircularExpansionIndustriesSection) => {
  const {
    solution_circular_expansion_industries_image: backgroundImage,
    solution_circular_expansion_industries_title,
    solution_circular_expansion_industries_mobile_title,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const title: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_circular_expansion_industries_title, '')
      ),
    [solution_circular_expansion_industries_title]
  );

  const mobileTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_circular_expansion_industries_mobile_title, '')
      ),
    [solution_circular_expansion_industries_mobile_title]
  );

  const industryLinks = useMemo(
    () =>
      items.map(
        ({
          solution_circular_expansion_industry_link: link,
          solution_circular_expansion_industry_label: label,
        }) => ({ url: link.url, label })
      ),
    [items]
  );

  return {
    title,
    mobileTitle,
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    industryLinks,
    ...useTextBackgroundAnimation(),
  };
};
