import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useQuadBlockTextBackgroundData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';
import TertiaryContent from './TertiaryContent';
import QuarternaryContent from './QuarternaryContent';

import { Normalize } from 'utils/types';

export type TQuadBlockTextBackgroundSection =
  Normalize<Queries.SolutionDesktopQuadBlockTextBackgroundData_PrismicSolutionDataDesktopSolutionQuadBlockTextBackground_Fragment>;

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: repeat(3, 130.25rem) 172rem;
  row-gap: 3.25rem;
  align-content: center;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 140%;
`;

const QuadBlockTextBackgroundSection: React.FC<TQuadBlockTextBackgroundSection> = sliceData => {
  const {
    backgroundImage,
    backgroundRef,
    containerRef,
    primaryTitle,
    primaryDescription,
    secondaryTitle,
    secondaryDescription,
    tertiaryTitle,
    tertiaryDescription,
    quarternaryTitle,
    quarternaryDescription,
  } = useQuadBlockTextBackgroundData(sliceData);

  return (
    <Container ref={containerRef}>
      {backgroundImage ? (
        <BackgroundImage ref={backgroundRef}>
          <GatsbyImage {...backgroundImage} />
        </BackgroundImage>
      ) : null}
      <PrimaryContent title={primaryTitle} description={primaryDescription} />
      <SecondaryContent title={secondaryTitle} description={secondaryDescription} />
      <TertiaryContent title={tertiaryTitle} description={tertiaryDescription} />
      <QuarternaryContent title={quarternaryTitle} description={quarternaryDescription} />
    </Container>
  );
};

export const desktopQuadBlockTextBackgroundFragment = graphql`
  fragment SolutionDesktopQuadBlockTextBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionQuadBlockTextBackground {
      slice_type

      ...SolutionDesktopQuadBlockTextBackgroundPrimaryContentData
      ...SolutionDesktopQuadBlockTextBackgroundSecondaryContentData
      ...SolutionDesktopQuadBlockTextBackgroundTertiaryContentData
      ...SolutionDesktopQuadBlockTextBackgroundQuarternaryContentData

      primary {
        solution_quad_block_text_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default QuadBlockTextBackgroundSection;
