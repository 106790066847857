import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface IPrimaryContent {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 8rem;
  align-content: flex-end;
  justify-content: center;
  justify-items: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.primary};
      font-size: 8rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.lightBold)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    width: 100%;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.white};
      font-size: 2.75rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const PrimaryContent: React.FC<IPrimaryContent> = ({ title, description }) => {
  return (
    <Container>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopQuadBlockCircularExpansionBackgroundPrimaryContentFragment = graphql`
  fragment SolutionDesktopQuadBlockCircularExpansionBackgroundPrimaryContentData on PrismicSolutionDataDesktopSolutionQuadBlockCircularExpansionBackground {
    primary {
      solution_quad_block_circular_expansion_background_primary_title {
        richText
      }
      solution_quad_block_circular_expansion_background_primary_description {
        richText
      }
    }
  }
`;

export default PrimaryContent;
