import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface ISecondaryContent {
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  position: relative;
  align-content: flex-start;
  padding: 8.125rem 0 12.5rem 10.5rem;
`;

const Content = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    row-gap: 14.25rem;
    justify-items: flex-start;
    z-index: 1;
    background-color: ${colors.black}4D;
    padding: 1.75rem 5rem;
    width: 100%;
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    justify-content: center;

    & > p {
      line-height: 1.04;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ description }) => {
  return (
    <Container>
      <Content>
        <Description render={description} />
      </Content>
    </Container>
  );
};

export const desktopDualBlockTextBackgroundSingleSecondaryContentFragment = graphql`
  fragment SolutionDesktopDualBlockTextSingleBackgroundSecondaryContentData on PrismicSolutionDataDesktopSolutionDualBlockTextSingleBackground {
    primary {
      solution_dual_block_text_single_background_secondary_title {
        richText
      }
      solution_dual_block_text_single_background_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
