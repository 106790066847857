import PlaylistColumnSection, { PLAYLIST_COLUMN_SLICE_TYPE } from './PlaylistColumnSection';
import TextBackgroundSection, { TEXT_BACKGROUND_SLICE_TYPE } from './TextBackgroundSection';
import SingleToneDividerTextSection, {
  SINGLE_TONE_DIVIDER_TEXT_SLICE_TYPE,
} from './SingleToneDividerTextSection';
import DualToneTextSection, { DUAL_TONE_TEXT_SLICE_TYPE } from './DualToneTextSection';
import CircularExpansionIndustriesSection, {
  CIRCULAR_EXPANSION_INDUSTRIES_SLICE_TYPE,
} from './CircularExpansionIndustriesSection';
import DualBlockEndorsementsSection, {
  DUAL_BLOCK_ENDORSEMENTS_SLICE_TYPE,
} from './DualBlockEndorsementsSection';
import BottomCenteredTextBackgroundSection, {
  BOTTOM_CENTERED_TEXT_BACKGROUND_SLICE_TYPE,
} from './BottomCenteredTextBackgroundSection';
import TopCenteredTextBackgroundSection, {
  TOP_CENTERED_TEXT_BACKGROUND_SLICE_TYPE,
} from './TopCenteredTextBackgroundSection';
import TopPaddedTextBackgroundSection, {
  TOP_PADDED_TEXT_BACKGROUND_SLICE_TYPE,
} from './TopPaddedTextBackgroundSection';
import QuadBlockTextBackgroundSection, {
  QUAD_BLOCK_TEXT_BACKGROUND_SLICE_TYPE,
} from './QuadBlockTextBackgroundSection';
import ColumnsTextBackgroundSection, {
  COLUMNS_TEXT_BACKGROUND_SLICE_TYPE,
} from './ColumnsTextBackgroundSection';
import DualBlockTextBackgroundSection, {
  DUAL_BLOCK_TEXT_BACKGROUND_SLICE_TYPE,
} from './DualBlockTextBackgroundSection';
import DualBlockTextSingleBackgroundSection, {
  DUAL_BLOCK_TEXT_SINGLE_BACKGROUND_SLICE_TYPE,
} from './DualBlockTextSingleBackgroundSection';
import CenteredTextSection, { CENTERED_TEXT_SLICE_TYPE } from './CenteredTextSection';
import DualToneCenteredTextSection, {
  DUAL_TONE_CENTERED_TEXT_SLICE_TYPE,
} from './DualToneCenteredTextSection';
import GridImagesSection, { GRID_IMAGES_SLICE_TYPE } from './GridImagesSection';
import ColumnHeaderBackgroundSection, {
  COLUMN_HEADER_BACKGROUND_SLICE_TYPE,
} from './ColumnHeaderBackgroundSection';
import GridLabeledImagesSection, {
  GRID_LABELED_IMAGES_SLICE_TYPE,
} from './GridLabeledImagesSection';
import CenteredDividerTextSection, {
  CENTERED_DIVIDER_TEXT_SLICE_TYPE,
} from './CenteredDividerTextSection';
import DualBlockHighlightTextBackgroundSection, {
  DUAL_BLOCK_HIGHLIGHT_TEXT_BACKGROUND_SLICE_TYPE,
} from './DualBlockHighlightTextBackgroundSection';
import CircularExpansionTextBackgroundSection, {
  CIRCULAR_EXPANSION_TEXT_BACKGROUND_SLICE_TYPE,
} from './CircularExpansionTextBackgroundSection';
import ColumnLabeledTextBackgroundSection, {
  COLUMN_LABELED_TEXT_BACKGROUND_SLICE_TYPE,
} from './ColumnLabeledTextBackgroundSection';
import QuadBlockCircularExpansionBackgroundSection, {
  QUAD_BLOCK_CIRCULAR_EXPANSION_BACKGROUND_SLICE_TYPE,
} from './QuadBlockCircularExpansionBackgroundSection';
import DualBlockAnimatedTextBackgroundSection, {
  DUAL_BLOCK_ANIMATED_TEXT_BACKGROUND_SLICE_TYPE,
} from './DualBlockAnimatedTextBackgroundSection';
import TextLongBackgroundSection, {
  TEXT_LONG_BACKGROUND_SLICE_TYPE,
} from './TextLongBackgroundSection';
import RightPaddedDividerTextBackgroundSection, {
  RIGHT_PADDED_DIVIDER_TEXT_BACKGROUND_SLICE_TYPE,
} from './RightPaddedDividerTextBackgroundSection';
import TextBaseBackgroundSection, {
  TEXT_BASE_BACKGROUND_SLICE_TYPE,
} from './TextBaseBackgroundSection';
import LaptopMobileTextSection, { LAPTOP_MOBILE_TEXT_SLICE_TYPE } from './LaptopMobileTextSection';
import ColumnHighlightTextBackgroundSection, {
  COLUMN_HIGHLIGHT_TEXT_BACKGROUND_SLICE_TYPE,
} from './ColumnHighlightTextBackgroundSection';
import ClockTextBackgroundSection, {
  CLOCK_TEXT_BACKGROUND_SLICE_TYPE,
} from './ClockTextBackgroundSection';
import TextTripleWeightSection, { TEXT_TRIPLE_WEIGHT_SLICE_TYPE } from './TextTripleWeightSection';
import HeaderBackgroundSection, { HEADER_BACKGROUND_SLICE_TYPE } from './HeaderBackgroundSection';
import LeftPaddedTextBackgroundSection, {
  LEFT_PADDED_TEXT_BACKGROUND_SLICE_TYPE,
} from './LeftPaddedTextBackgroundSection';
import HeaderTextSection, { HEADER_TEXT_SLICE_TYPE } from './HeaderTextSection';
import RightPaddedTextBackgroundSection, {
  RIGHT_PADDED_TEXT_BACKGROUND_SLICE_TYPE,
} from './RightPaddedTextBackgroundSection';
import CircularExpansionHeaderBackgroundSection, {
  CIRCULAR_EXPANSION_HEADER_BACKGROUND_SLICE_TYPE,
} from './CircularExpansionHeaderBackgroundSection';
import CenterRightPaddedTextBackgroundSection, {
  CENTER_RIGHT_PADDED_TEXT_BACKGROUND_SLICE_TYPE,
} from './CenterRightPaddedTextBackgroundSection';

export default {
  [PLAYLIST_COLUMN_SLICE_TYPE]: PlaylistColumnSection,
  [TEXT_BACKGROUND_SLICE_TYPE]: TextBackgroundSection,
  [SINGLE_TONE_DIVIDER_TEXT_SLICE_TYPE]: SingleToneDividerTextSection,
  [DUAL_TONE_TEXT_SLICE_TYPE]: DualToneTextSection,
  [CIRCULAR_EXPANSION_INDUSTRIES_SLICE_TYPE]: CircularExpansionIndustriesSection,
  [DUAL_BLOCK_ENDORSEMENTS_SLICE_TYPE]: DualBlockEndorsementsSection,
  [BOTTOM_CENTERED_TEXT_BACKGROUND_SLICE_TYPE]: BottomCenteredTextBackgroundSection,
  [TOP_CENTERED_TEXT_BACKGROUND_SLICE_TYPE]: TopCenteredTextBackgroundSection,
  [TOP_PADDED_TEXT_BACKGROUND_SLICE_TYPE]: TopPaddedTextBackgroundSection,
  [QUAD_BLOCK_TEXT_BACKGROUND_SLICE_TYPE]: QuadBlockTextBackgroundSection,
  [COLUMNS_TEXT_BACKGROUND_SLICE_TYPE]: ColumnsTextBackgroundSection,
  [DUAL_BLOCK_TEXT_BACKGROUND_SLICE_TYPE]: DualBlockTextBackgroundSection,
  [DUAL_BLOCK_TEXT_SINGLE_BACKGROUND_SLICE_TYPE]: DualBlockTextSingleBackgroundSection,
  [CENTERED_TEXT_SLICE_TYPE]: CenteredTextSection,
  [DUAL_TONE_CENTERED_TEXT_SLICE_TYPE]: DualToneCenteredTextSection,
  [GRID_IMAGES_SLICE_TYPE]: GridImagesSection,
  [COLUMN_HEADER_BACKGROUND_SLICE_TYPE]: ColumnHeaderBackgroundSection,
  [GRID_LABELED_IMAGES_SLICE_TYPE]: GridLabeledImagesSection,
  [CENTERED_DIVIDER_TEXT_SLICE_TYPE]: CenteredDividerTextSection,
  [DUAL_BLOCK_HIGHLIGHT_TEXT_BACKGROUND_SLICE_TYPE]: DualBlockHighlightTextBackgroundSection,
  [CIRCULAR_EXPANSION_TEXT_BACKGROUND_SLICE_TYPE]: CircularExpansionTextBackgroundSection,
  [COLUMN_LABELED_TEXT_BACKGROUND_SLICE_TYPE]: ColumnLabeledTextBackgroundSection,
  [QUAD_BLOCK_CIRCULAR_EXPANSION_BACKGROUND_SLICE_TYPE]:
    QuadBlockCircularExpansionBackgroundSection,
  [DUAL_BLOCK_ANIMATED_TEXT_BACKGROUND_SLICE_TYPE]: DualBlockAnimatedTextBackgroundSection,
  [TEXT_LONG_BACKGROUND_SLICE_TYPE]: TextLongBackgroundSection,
  [RIGHT_PADDED_DIVIDER_TEXT_BACKGROUND_SLICE_TYPE]: RightPaddedDividerTextBackgroundSection,
  [TEXT_BASE_BACKGROUND_SLICE_TYPE]: TextBaseBackgroundSection,
  [LAPTOP_MOBILE_TEXT_SLICE_TYPE]: LaptopMobileTextSection,
  [COLUMN_HIGHLIGHT_TEXT_BACKGROUND_SLICE_TYPE]: ColumnHighlightTextBackgroundSection,
  [CLOCK_TEXT_BACKGROUND_SLICE_TYPE]: ClockTextBackgroundSection,
  [TEXT_TRIPLE_WEIGHT_SLICE_TYPE]: TextTripleWeightSection,
  [HEADER_BACKGROUND_SLICE_TYPE]: HeaderBackgroundSection,
  [LEFT_PADDED_TEXT_BACKGROUND_SLICE_TYPE]: LeftPaddedTextBackgroundSection,
  [HEADER_TEXT_SLICE_TYPE]: HeaderTextSection,
  [RIGHT_PADDED_TEXT_BACKGROUND_SLICE_TYPE]: RightPaddedTextBackgroundSection,
  [CIRCULAR_EXPANSION_HEADER_BACKGROUND_SLICE_TYPE]: CircularExpansionHeaderBackgroundSection,
  [CENTER_RIGHT_PADDED_TEXT_BACKGROUND_SLICE_TYPE]: CenterRightPaddedTextBackgroundSection,
};
