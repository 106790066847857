import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { TTextBackgroundSection } from './TextBackgroundSection';
import { SPACING } from './constants';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useTextBackgroundData = ({ primary }: TTextBackgroundSection) => {
  const {
    solution_text_background_image: backgroundImage,
    solution_text_background_title,
    solution_text_background_description: description,
    solution_text_background_spacing: spacing,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const title: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(solution_text_background_title, '')),
    [solution_text_background_title]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
    spacing: spacing as SPACING,
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
