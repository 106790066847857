import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css, PropsOf } from '@emotion/react';

import desktopSolutionsMap from './desktopSolutionsMap';

import { useSolution } from '../SolutionContext';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: block;

    ${mq.md} {
      && > * {
        display: none;
      }
    }
  `}
`;

const DesktopContent: React.FC = () => {
  const { desktop } = useSolution().solution.data;

  return desktop.length ? (
    <Container>
      {desktop.map((desktopData, index) => {
        const type = (desktopData as { slice_type: string }).slice_type;
        const Section = desktopSolutionsMap[type];

        return Section ? (
          <Section key={`${type}${index}`} {...(desktopData as PropsOf<typeof Section>)} />
        ) : null;
      })}
    </Container>
  ) : null;
};

export const desktopContentFragment = graphql`
  fragment SolutionDesktopContentData on PrismicSolution {
    data {
      desktop {
        ...SolutionDesktopPlaylistColumnData
        ...SolutionDesktopTextBackgroundData
        ...SolutionDesktopSingleToneDividerTextData
        ...SolutionDesktopDualToneTextData
        ...SolutionDesktopCircularExpansionIndustriesData
        ...SolutionDesktopDualBlockEndorsementsData
        ...SolutionDesktopBottomCenteredTextBackgroundData
        ...SolutionDesktopTopCenteredTextBackgroundData
        ...SolutionDesktopTopPaddedTextBackgroundData
        ...SolutionDesktopQuadBlockTextBackgroundData
        ...SolutionDesktopColumnsTextBackgroundData
        ...SolutionDesktopDualBlockTextBackgroundData
        ...SolutionDesktopDualBlockTextSingleBackgroundData
        ...SolutionDesktopCenteredTextData
        ...SolutionDesktopDualToneCenteredTextData
        ...SolutionDesktopGridImagesData
        ...SolutionDesktopColumnHeaderBackgroundData
        ...SolutionDesktopGridLabeledImagesData
        ...SolutionDesktopCenteredDividerTextData
        ...SolutionDesktopDualBlockHighlightTextBackgroundData
        ...SolutionDesktopCircularExpansionTextBackgroundData
        ...SolutionDesktopColumnLabeledTextBackgroundData
        ...SolutionDesktopQuadBlockCircularExpansionBackgroundData
        ...SolutionDesktopDualBlockAnimatedTextBackgroundData
        ...SolutionDesktopTextLongBackgroundData
        ...SolutionDesktopRightPaddedDividerTextBackgroundData
        ...SolutionDesktopTextBaseBackgroundData
        ...SolutionDesktopLaptopMobileTextData
        ...SolutionDesktopColumnHighlightTextBackgroundData
        ...SolutionDesktopClockTextBackgroundData
        ...SolutionDesktopTextTripleWeightData
        ...SolutionDesktopHeaderBackgroundData
        ...SolutionDesktopLeftPaddedTextBackgroundData
        ...SolutionDesktopHeaderTextData
        ...SolutionDesktopRightPaddedTextBackgroundData
        ...SolutionDesktopCircularExpansionHeaderBackgroundData
        ...SolutionDesktopCenterRightPaddedTextBackgroundData
      }
    }
  }
`;

export default DesktopContent;
