import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { TGridLabeledImagesSection } from './GridLabeledImagesSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useGridLabeledImagesData = ({ primary, items }: TGridLabeledImagesSection) => {
  const { solution_grid_labeled_images_title } = primary;

  const gridImages = items.reduce(
    (
      acm,
      {
        solution_grid_labeled_images_grid_image: image,
        solution_grid_labeled_images_grid_label: label,
      }
    ) => {
      if (!image || !image.gatsbyImageData) return acm;

      const { gatsbyImageData, alt } = image;
      return [
        ...acm,
        {
          image: gatsbyImageData,
          alt: alt ?? '',
          label: richTextWithPlaceholder(label, ''),
        },
      ];
    },
    []
  );

  const title: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(solution_grid_labeled_images_title, '')),
    [solution_grid_labeled_images_title]
  );

  return {
    title,
    gridImages,
  };
};
