import { RichTextBlock } from 'prismic-reactjs';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';

import { TDualBlockEndorsementsSection } from './DualBlockEndorsementsSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useDualBlockEndorsementsData = ({ primary, items }: TDualBlockEndorsementsSection) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          translateY: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top center',
            end: `bottom center`,
            scrub: true,
          },
          translateY: '-14%',
        }
      );
    });

    return () => context.revert();
  }, []);

  const {
    solution_dual_block_endorsements_background_image: backgroundImage,
    solution_dual_block_endorsements_primary_title,
    solution_dual_block_endorsements_primary_description: primaryDescription,
    solution_dual_block_endorsements_secondary_title: secondaryTitle,
    solution_dual_block_endorsements_secondary_description: secondaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const primaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_dual_block_endorsements_primary_title, '')
      ),
    [solution_dual_block_endorsements_primary_title]
  );

  const icons = useMemo(
    () =>
      items.reduce((acm, { solution_dual_block_endorsements_icon: { gatsbyImageData, alt } }) => {
        return gatsbyImageData ? [...acm, { image: gatsbyImageData, alt: alt ?? '' }] : acm;
      }, []),
    [items]
  );

  const iconSplitIndex = Math.ceil(icons.length / 2);

  return {
    primaryTitle,
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    backgroundRef,
    containerRef,
    leftIcons: icons.slice(0, iconSplitIndex),
    rightIcons: icons.slice(iconSplitIndex),
  };
};
