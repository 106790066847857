import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { TTopPaddedTextBackgroundSection } from './TopPaddedTextBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useTopPaddedTextBackgroundData = ({ primary }: TTopPaddedTextBackgroundSection) => {
  const {
    solution_top_padded_text_background_image: backgroundImage,
    solution_top_padded_text_background_title,
    solution_top_padded_text_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;
  const title: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_top_padded_text_background_title, '')
      ),
    [solution_top_padded_text_background_title]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
