import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface IPrimaryContent {
  title: RichTextBlock[][];
  description: RichTextBlock[];
}

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  row-gap: 14.75rem;
  align-content: flex-start;
  justify-content: flex-start;
  z-index: 1;
  padding: 29.375rem 0 0 12.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      background-color: ${colors.white}CC;
      padding: 1rem 4rem 1rem 1.5rem;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.black};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    background-color: ${colors.white}CC;

    & > p {
      padding: 1rem 4rem 4rem 1.5rem;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const PrimaryContent: React.FC<IPrimaryContent> = ({ title, description }) => {
  return (
    <Container>
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
      <Description render={description} />
    </Container>
  );
};

export const desktopDualBlockHighlightTextBackgroundPrimaryContentFragment = graphql`
  fragment SolutionDesktopDualBlockHighlightTextBackgroundPrimaryContentData on PrismicSolutionDataDesktopSolutionDualBlockHighlightTextBackground {
    primary {
      solution_dual_block_highlight_text_background_primary_title {
        richText
      }
      solution_dual_block_highlight_text_background_primary_description {
        richText
      }
    }
  }
`;

export default PrimaryContent;
