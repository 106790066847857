import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import { TDualBlockAnimatedTextBackgroundSection } from './DualBlockAnimatedTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

const useDualBlockAnimatedTextBackgroundAnimation = () => {
  const [resizeId, setResizeId] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const primaryContentRef = useRef<HTMLDivElement>(null);
  const primaryWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => setResizeId(id => id + 1);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      if (primaryWrapperRef.current) {
        gsap.fromTo(
          backgroundRef.current,
          {
            translateY: '0%',
          },
          {
            scrollTrigger: {
              trigger: containerRef.current,
              start: 'top top',
              end: `bottom bottom`,
              scrub: true,
            },
            translateY: '-20%',
          }
        );

        const wrapperHeight = primaryWrapperRef.current.offsetHeight / 2;

        gsap.fromTo(
          primaryWrapperRef.current,
          {
            top: 0,
            translateY: 0,
          },
          {
            scrollTrigger: {
              trigger: primaryContentRef.current,
              start: `top+=${wrapperHeight} center`,
              end: `bottom-=${wrapperHeight} center`,
              scrub: true,
            },
            top: '100%',
            translateY: '-100%',
            ease: 'none',
          }
        );
      }
    });

    return () => context.revert();
  }, [resizeId]);

  return { containerRef, backgroundRef, primaryContentRef, primaryWrapperRef };
};

export const useDualBlockAnimatedTextBackgroundData = ({
  primary,
}: TDualBlockAnimatedTextBackgroundSection) => {
  const {
    solution_dual_block_animated_text_background_image: backgroundImage,
    solution_dual_block_animated_text_background_primary_title: primaryTitle,
    solution_dual_block_animated_text_background_primary_description: primaryDescription,
    solution_dual_block_animated_text_background_secondary_title: secondaryTitle,
    solution_dual_block_animated_text_background_secondary_description: secondaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    ...useDualBlockAnimatedTextBackgroundAnimation(),
  };
};
