import { RichTextBlock } from 'prismic-reactjs';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';

import { TDualBlockHighlightTextBackground } from './DualBlockHighlightTextBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useDualBlockHighlightTextBackgroundData = ({
  primary,
}: TDualBlockHighlightTextBackground) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          translateY: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          translateY: '-15%',
        }
      );
    });

    return () => context.revert();
  }, []);

  const {
    solution_dual_block_highlight_text_background_image: backgroundImage,
    solution_dual_block_highlight_text_background_primary_title,
    solution_dual_block_highlight_text_background_primary_description: primaryDescription,
    solution_dual_block_highlight_text_background_secondary_title: secondaryTitle,
    solution_dual_block_highlight_text_background_secondary_description: secondaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const primaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_dual_block_highlight_text_background_primary_title, '')
      ),
    [solution_dual_block_highlight_text_background_primary_title]
  );

  return {
    primaryTitle,
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    backgroundRef,
    containerRef,
  };
};
