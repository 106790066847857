import { TRightPaddedTextBackgroundSection } from './RightPaddedTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useRightPaddedTextBackgroundData = ({
  primary,
}: TRightPaddedTextBackgroundSection) => {
  const {
    solution_right_padded_text_background_image: backgroundImage,
    solution_right_padded_text_background_title: title,
    solution_right_padded_text_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
