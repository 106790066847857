import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useBottomCenteredTextBackgroundData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TBottomCenteredTextBackgroundSection =
  Normalize<Queries.SolutionDesktopBottomCenteredTextBackgroundData_PrismicSolutionDataDesktopSolutionBottomCenteredTextBackground_Fragment>;

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  position: relative;
  row-gap: 19.875rem;
  align-content: flex-end;
  justify-content: center;
  padding-bottom: 11.25rem;
  height: 193.25rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const GreyedBackgroundImage = styled(BackgroundImage)`
  z-index: 1;
  filter: grayscale(1);
  clip-path: polygon(0% 0%, 100% 0%, 100% 38%, 0% 38%);
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    z-index: 1;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.black};
      font-size: 11.125rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.lightBold)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;
    z-index: 1;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const BottomCenteredTextBackgroundSection: React.FC<
  TBottomCenteredTextBackgroundSection
> = sliceData => {
  const { backgroundImage, title, description } = useBottomCenteredTextBackgroundData(sliceData);

  return (
    <Container>
      {backgroundImage ? (
        <>
          <GreyedBackgroundImage {...backgroundImage} />
          <BackgroundImage {...backgroundImage} />
        </>
      ) : null}
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopBottomCenteredTextBackgroundFragment = graphql`
  fragment SolutionDesktopBottomCenteredTextBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionBottomCenteredTextBackground {
      slice_type

      primary {
        solution_bottom_centered_text_background_background_image {
          gatsbyImageData
          alt
        }
        solution_bottom_centered_text_background_title {
          richText
        }
        solution_bottom_centered_text_background_description {
          richText
        }
      }
    }
  }
`;

export default BottomCenteredTextBackgroundSection;
