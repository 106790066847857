import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

import { TDualBlockTextBackgroundSection } from './DualBlockTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useDualBlockTextBackgroundData = ({ primary }: TDualBlockTextBackgroundSection) => {
  const {
    solution_dual_block_text_background_primary_image: primaryImage,
    solution_dual_block_text_background_primary_title: primaryTitle,
    solution_dual_block_text_background_primary_description: primaryDescription,
    solution_dual_block_text_background_secondary_image: secondaryImage,
    solution_dual_block_text_background_secondary_title: secondaryTitle,
    solution_dual_block_text_background_secondary_description: secondaryDescription,
  } = primary;

  return {
    primaryImage: primaryImage.gatsbyImageData
      ? { image: primaryImage.gatsbyImageData, alt: primaryImage.alt ?? '' }
      : null,
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryImage: secondaryImage.gatsbyImageData
      ? { image: secondaryImage.gatsbyImageData, alt: secondaryImage.alt ?? '' }
      : null,
    secondaryTitle: richTextWithPlaceholder(secondaryTitle, ''),
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
  };
};

export const useDualBlockTextBackgroundPrimaryContentAnimation = () => {
  const [resizeId, setResizeId] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => setResizeId(id => id + 1);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      if (wrapperRef.current) {
        gsap.fromTo(
          backgroundRef.current,
          {
            translateY: '0%',
          },
          {
            scrollTrigger: {
              trigger: containerRef.current,
              start: 'top top',
              end: `bottom bottom`,
              scrub: true,
            },
            translateY: '-20%',
          }
        );

        const wrapperHeight = wrapperRef.current.offsetHeight / 2;

        gsap.to(wrapperRef.current, {
          scrollTrigger: {
            trigger: contentRef.current,
            start: `top+=${wrapperHeight} center`,
            end: `bottom-=${wrapperHeight} center`,
            scrub: true,
          },
          top: '145rem',
          ease: 'none',
        });
      }
    });

    return () => context.revert();
  }, [resizeId]);

  return { containerRef, backgroundRef, contentRef, wrapperRef };
};
