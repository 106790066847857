import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useQuadBlockCircularExpansionBackgroundData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';
import TertiaryContent from './TertiaryContent';
import QuarternaryContent from './QuarternaryContent';

import { Normalize } from 'utils/types';

export type TQuadBlockCircularExpansionBackgroundSection =
  Normalize<Queries.SolutionDesktopQuadBlockCircularExpansionBackgroundData_PrismicSolutionDataDesktopSolutionQuadBlockCircularExpansionBackground_Fragment>;

export enum TEMPLATE_ROW_LABELS {
  primarySecondary = 'primary-secondary',
  tertiary = 'tertiary',
  quarternary = 'quarternary',
}

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows:
    [ ${TEMPLATE_ROW_LABELS.primarySecondary}]
    auto
    1fr
    [ ${TEMPLATE_ROW_LABELS.tertiary}]
    auto
    5fr
    [ ${TEMPLATE_ROW_LABELS.quarternary}]
    auto;
  align-content: space-between;
  padding-bottom: 14.125rem;
  height: 410.75rem;
  overflow: hidden;
`;

const PrimarySecondaryContainer = styled.div`
  display: grid;
  grid-row-start: ${TEMPLATE_ROW_LABELS.primarySecondary};
  row-gap: 26.75rem;
  align-content: space-between;
  z-index: 1;
  background-color: rgb(31 31 31 / 70%);
  padding: 14.125rem 0 19.25rem;
`;

const TertiaryContainer = styled(TertiaryContent)`
  grid-row-start: ${TEMPLATE_ROW_LABELS.tertiary};
`;

const QuarternaryContainer = styled(QuarternaryContent)`
  grid-row-start: ${TEMPLATE_ROW_LABELS.quarternary};
`;

const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 140%;
`;

const QuadBlockCircularExpansionBackgroundSection: React.FC<
  TQuadBlockCircularExpansionBackgroundSection
> = sliceData => {
  const {
    backgroundImage,
    backgroundRef,
    containerRef,
    primaryTitle,
    primaryDescription,
    secondaryTitle,
    secondaryDescription,
    tertiaryTitle,
    tertiaryDescription,
    quarternaryTitle,
    quarternaryDescription,
  } = useQuadBlockCircularExpansionBackgroundData(sliceData);

  return (
    <Container ref={containerRef}>
      {backgroundImage ? (
        <BackgroundImage ref={backgroundRef}>
          <GatsbyImage {...backgroundImage} />
        </BackgroundImage>
      ) : null}
      <PrimarySecondaryContainer>
        <PrimaryContent title={primaryTitle} description={primaryDescription} />
        <SecondaryContent title={secondaryTitle} description={secondaryDescription} />
      </PrimarySecondaryContainer>
      <TertiaryContainer title={tertiaryTitle} description={tertiaryDescription} />
      <QuarternaryContainer title={quarternaryTitle} description={quarternaryDescription} />
    </Container>
  );
};

export const desktopQuadBlockCircularExpansionBackgroundFragment = graphql`
  fragment SolutionDesktopQuadBlockCircularExpansionBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionQuadBlockCircularExpansionBackground {
      slice_type

      ...SolutionDesktopQuadBlockCircularExpansionBackgroundPrimaryContentData
      ...SolutionDesktopQuadBlockCircularExpansionBackgroundSecondaryContentData
      ...SolutionDesktopQuadBlockCircularExpansionBackgroundTertiaryContentData
      ...SolutionDesktopQuadBlockCircularExpansionBackgroundQuarternaryContentData

      primary {
        solution_quad_block_circular_expansion_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default QuadBlockCircularExpansionBackgroundSection;
