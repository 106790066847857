import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useDualBlockHighlightTextBackgroundData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';

import { Normalize } from 'utils/types';

export type TDualBlockHighlightTextBackground =
  Normalize<Queries.SolutionDesktopDualBlockHighlightTextBackgroundData_PrismicSolutionDataDesktopSolutionDualBlockHighlightTextBackground_Fragment>;

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 1fr 1fr;
  align-content: center;
  height: 279.25rem;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 120%;
`;

const BackgroundImage = styled(GatsbyImage)`
  filter: grayscale(1);
`;

const DualBlockHighlightTextBackgroundSection: React.FC<
  TDualBlockHighlightTextBackground
> = sliceData => {
  const {
    backgroundImage,
    backgroundRef,
    containerRef,
    primaryTitle,
    primaryDescription,
    secondaryTitle,
    secondaryDescription,
  } = useDualBlockHighlightTextBackgroundData(sliceData);

  return (
    <Container ref={containerRef}>
      {backgroundImage ? (
        <ImageContainer ref={backgroundRef}>
          <BackgroundImage {...backgroundImage} />
        </ImageContainer>
      ) : null}
      <PrimaryContent title={primaryTitle} description={primaryDescription} />
      <SecondaryContent title={secondaryTitle} description={secondaryDescription} />
    </Container>
  );
};

export const desktopDualBlockEndorsementsFragment = graphql`
  fragment SolutionDesktopDualBlockHighlightTextBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionDualBlockHighlightTextBackground {
      slice_type

      ...SolutionDesktopDualBlockHighlightTextBackgroundPrimaryContentData
      ...SolutionDesktopDualBlockHighlightTextBackgroundSecondaryContentData

      primary {
        solution_dual_block_highlight_text_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default DualBlockHighlightTextBackgroundSection;
