import { TLaptopMobileTextSection } from './LaptopMobileTextSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useLaptopMobileTextData = ({ primary }: TLaptopMobileTextSection) => {
  const {
    solution_laptop_mobile_text_laptop_image: laptopImage,
    solution_laptop_mobile_text_mobile_image: mobileImage,
    solution_laptop_mobile_text_laptop_title: laptopTitle,
    solution_laptop_mobile_text_mobile_title: mobileTitle,
    solution_laptop_mobile_text_description: description,
  } = primary;

  return {
    laptopImage: laptopImage.gatsbyImageData
      ? { image: laptopImage.gatsbyImageData, alt: laptopImage.alt ?? '' }
      : null,
    mobileImage: mobileImage.gatsbyImageData
      ? { image: mobileImage.gatsbyImageData, alt: mobileImage.alt ?? '' }
      : null,
    laptopTitle: richTextWithPlaceholder(laptopTitle, ''),
    mobileTitle: richTextWithPlaceholder(mobileTitle, ''),
    description: richTextWithPlaceholder(description, ''),
  };
};
