import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { TColumnsTextBackgroundSection } from './ColumnsTextBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useColumnsTextBackgroundData = ({ primary, items }: TColumnsTextBackgroundSection) => {
  const {
    solution_columns_text_background_title,
    solution_columns_text_background_description: description,
  } = primary;

  const columnImages = items.reduce(
    (acm, { solution_columns_text_background_column_image: image }) => {
      if (!image || !image.gatsbyImageData) return acm;

      const { gatsbyImageData, alt } = image;
      return [...acm, { image: gatsbyImageData, alt: alt ?? '' }];
    },
    []
  );

  const title: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(richTextWithPlaceholder(solution_columns_text_background_title, '')),
    [solution_columns_text_background_title]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
    columnImages,
  };
};
