import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface IQuarternaryContent {
  title: RichTextBlock[][];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 4rem;
  align-content: flex-start;
  z-index: 1;
  padding: 29.25rem 0 0 21.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      background-color: ${colors.black}4D;
      padding-right: 8rem;
      text-align: left;
      text-shadow: 0.25rem 1px 0 #646464;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    background-color: ${colors.white}CC;

    & > p {
      padding: 0 3rem 9rem 0;
      text-align: left;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const QuarternaryContent: React.FC<IQuarternaryContent> = ({ title, description }) => {
  return (
    <Container>
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
      <Description render={description} />
    </Container>
  );
};

export const desktopQuadBlockTextBackgroundQuarternaryContentFragment = graphql`
  fragment SolutionDesktopQuadBlockTextBackgroundQuarternaryContentData on PrismicSolutionDataDesktopSolutionQuadBlockTextBackground {
    primary {
      solution_quad_block_text_background_quarternary_title {
        richText
      }
      solution_quad_block_text_background_quarternary_description {
        richText
      }
    }
  }
`;

export default QuarternaryContent;
