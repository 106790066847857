import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useTextDividerImageData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TRightPaddedDividerTextBackgroundSection =
  Normalize<Queries.SolutionDesktopRightPaddedDividerTextBackgroundData_PrismicSolutionDataDesktopSolutionRightPaddedDividerTextBackground_Fragment>;

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  position: relative;
  row-gap: 10.25rem;
  justify-content: flex-end;
  padding-right: 10rem;
  height: 137.25rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: grid;
  align-content: center;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    &::after {
      display: block;
      margin: 5rem 0;
      background-color: ${colors.primary};
      width: 11.5rem;
      height: 0.25rem;
      content: '';
    }

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.primary};
      font-size: 9rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      & strong {
        ${tp.useFont(fonts => fonts.Switzer.bold)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;
    z-index: 1;

    & > p {
      text-align: center;
      line-height: 1.2;
      color: ${colors.white};
      font-size: 2.75rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const RightPaddedDividerTextBackgroundSection: React.FC<
  TRightPaddedDividerTextBackgroundSection
> = sliceData => {
  const { backgroundImage, title, description } = useTextDividerImageData(sliceData);

  return (
    <Container>
      {backgroundImage ? <BackgroundImage {...backgroundImage} /> : null}
      <Content>
        <Title render={title} />
        <Description render={description} />
      </Content>
    </Container>
  );
};

export const desktopRightPaddedDividerTextBackgroundFragment = graphql`
  fragment SolutionDesktopRightPaddedDividerTextBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionRightPaddedDividerTextBackground {
      slice_type

      primary {
        solution_right_padded_divider_text_background_image {
          gatsbyImageData
          alt
        }
        solution_right_padded_divider_text_background_title {
          richText
        }
        solution_right_padded_divider_text_background_description {
          richText
        }
      }
    }
  }
`;

export default RightPaddedDividerTextBackgroundSection;
