import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface IPrimaryContent {
  title: RichTextBlock[][];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 7.25rem;
  align-content: center;
  z-index: 1;
  padding-left: 23.5rem;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      background-color: ${colors.black}80;
      padding-right: 1.5rem;
      text-align: left;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 12.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    background-color: ${colors.black}4D;
    backdrop-filter: blur(1.875rem);
    width: fit-content;

    & > p {
      padding: 1.5rem 2rem;
      text-align: left;
      line-height: 1.2;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const PrimaryContent: React.FC<IPrimaryContent> = ({ title, description }) => {
  return (
    <Container>
      <div>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </div>
      <Description render={description} />
    </Container>
  );
};

export const desktopDualBlockEndorsementsPrimaryContentFragment = graphql`
  fragment SolutionDesktopDualBlockEndorsementsPrimaryContentData on PrismicSolutionDataDesktopSolutionDualBlockEndorsements {
    primary {
      solution_dual_block_endorsements_primary_title {
        richText
      }
      solution_dual_block_endorsements_primary_description {
        richText
      }
    }
  }
`;

export default PrimaryContent;
