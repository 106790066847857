import { TCenterRightPaddedTextBackgroundSection } from './CenterRightPaddedTextBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useCenterRightPaddedTextBackgroundData = ({
  primary,
}: TCenterRightPaddedTextBackgroundSection) => {
  const {
    solution_center_right_padded_text_background_title: title,
    solution_center_right_padded_text_background_description: description,
    solution_center_right_padded_text_background_image: backgroundImage,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
