import { TPlaylistColumnSection } from './PlaylistColumnSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const usePlaylistColumnData = ({ primary, items }: TPlaylistColumnSection) => {
  const {
    solution_playlist_column_is_dark: isDark,
    solution_playlist_column_title: title,
    solution_playlist_column_subtitle: subtitle,
    solution_playlist_column_description: description,
    solution_playlist_column_playlists_title: playlistsTitle,
  } = primary;

  const playlists = items.reduce(
    (
      acm,
      {
        solution_playlist_column_track: track,
        solution_playlist_column_track_name: name,
        solution_playlist_column_track_thumbnail: thumbnail,
      }
    ) => {
      if (!track.url || !name || !thumbnail) return acm;

      const { gatsbyImageData, alt } = thumbnail;

      return [
        ...acm,
        {
          track: track.url,
          name,
          thumbnail: { image: gatsbyImageData, alt: alt ?? name },
        },
      ];
    },
    []
  );

  return {
    isDark,
    title: richTextWithPlaceholder(title, ''),
    subtitle: richTextWithPlaceholder(subtitle, ''),
    description: richTextWithPlaceholder(description, ''),
    playlistsTitle,
    playlists,
  };
};
