import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface ISecondaryContent {
  title: RichTextBlock[][];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 13.25rem;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: right;
      line-height: 0.77;
      letter-spacing: -0.11em;
      color: ${colors.primary};
      font-size: 15rem;

      ${tp.useFont(fonts => fonts.Switzer.regular)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > p {
      text-align: left;
      line-height: 1.02;
      color: ${colors.white}CC;
      font-size: 4.75rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ title, description }) => {
  return (
    <Container>
      <TitleContainer>
        {title.map(titleText => (
          <Title key={JSON.stringify(titleText)} render={titleText} />
        ))}
      </TitleContainer>
      <Description render={description} />
    </Container>
  );
};

export const desktopQuadBlockCircularExpansionBackgroundSecondaryContentFragment = graphql`
  fragment SolutionDesktopQuadBlockCircularExpansionBackgroundSecondaryContentData on PrismicSolutionDataDesktopSolutionQuadBlockCircularExpansionBackground {
    primary {
      solution_quad_block_circular_expansion_background_secondary_title {
        richText
      }
      solution_quad_block_circular_expansion_background_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
