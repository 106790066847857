import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { TColumnHeaderBackgroundSection } from './ColumnHeaderBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useColumnHeaderBackgroundData = ({
  primary,
  items,
}: TColumnHeaderBackgroundSection) => {
  const { solution_column_header_background_title } = primary;

  const columnImages = items.reduce(
    (acm, { solution_column_header_background_column_image: image }) => {
      if (!image || !image.gatsbyImageData) return acm;

      const { gatsbyImageData, alt } = image;
      return [...acm, { image: gatsbyImageData, alt: alt ?? '' }];
    },
    []
  );

  const title: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(richTextWithPlaceholder(solution_column_header_background_title, '')),
    [solution_column_header_background_title]
  );

  return {
    title,
    columnImages,
  };
};
