import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import { useDualBlockTextBackgroundData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';

import { Normalize } from 'utils/types';

export type TDualBlockTextBackgroundSection =
  Normalize<Queries.SolutionDesktopDualBlockTextBackgroundData_PrismicSolutionDataDesktopSolutionDualBlockTextBackground_Fragment>;

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 257.75rem 101.375rem;
  align-content: center;
  overflow: hidden;
`;

const DualBlockTextBackgroundSection: React.FC<TDualBlockTextBackgroundSection> = sliceData => {
  const {
    primaryImage,
    primaryTitle,
    primaryDescription,
    secondaryImage,
    secondaryTitle,
    secondaryDescription,
  } = useDualBlockTextBackgroundData(sliceData);

  return (
    <Container>
      <PrimaryContent image={primaryImage} title={primaryTitle} description={primaryDescription} />
      <SecondaryContent
        image={secondaryImage}
        title={secondaryTitle}
        description={secondaryDescription}
      />
    </Container>
  );
};

export const desktopDualBlockTextBackgroundFragment = graphql`
  fragment SolutionDesktopDualBlockTextBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionDualBlockTextBackground {
      slice_type

      ...SolutionDesktopDualBlockTextBackgroundPrimaryContentData
      ...SolutionDesktopDualBlockTextBackgroundSecondaryContentData
    }
  }
`;

export default DualBlockTextBackgroundSection;
