import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useCenterRightPaddedTextBackgroundData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TCenterRightPaddedTextBackgroundSection =
  Normalize<Queries.SolutionDesktopCenterRightPaddedTextBackgroundData_PrismicSolutionDataDesktopSolutionCenterRightPaddedTextBackground_Fragment>;

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  position: relative;
  row-gap: 7.75rem;
  align-content: flex-start;
  justify-content: flex-start;
  background-color: #f3f3f3;
  padding: 46.625rem 0 0 94.375rem;
  height: 139.625rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    z-index: 1;

    & > h2 {
      text-shadow: 0 0.5rem 0.5rem ${colors.black}66;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.white};
      font-size: 10rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;
    z-index: 1;

    & > p {
      line-height: 1.2;
      color: ${colors.black};
      font-size: 2.75rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const CenterRightPaddedTextBackgroundSection: React.FC<
  TCenterRightPaddedTextBackgroundSection
> = sliceData => {
  const { backgroundImage, title, description } = useCenterRightPaddedTextBackgroundData(sliceData);

  return (
    <Container>
      {backgroundImage ? <BackgroundImage {...backgroundImage} /> : null}
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopCenterRightPaddedTextBackgroundFragment = graphql`
  fragment SolutionDesktopCenterRightPaddedTextBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionCenterRightPaddedTextBackground {
      slice_type

      primary {
        solution_center_right_padded_text_background_image {
          gatsbyImageData
          alt
        }
        solution_center_right_padded_text_background_title {
          richText
        }
        solution_center_right_padded_text_background_description {
          richText
        }
      }
    }
  }
`;

export default CenterRightPaddedTextBackgroundSection;
