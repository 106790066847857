import { TTextBaseBackgroundSection } from './TextBaseBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useTextBaseBackgroundData = ({ primary }: TTextBaseBackgroundSection) => {
  const {
    solution_text_base_background_image: backgroundImage,
    solution_text_base_background_title: title,
    solution_text_base_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
