import { RichTextBlock } from 'prismic-reactjs';
import React, { useMemo, useRef } from 'react';

import { TCircularExpansionHeaderBackgroundSection } from './CircularExpansionHeaderBackgroundSection';

import useMaskCircle from 'utils/animations/useMaskCircle';
import {
  ANIMATION_COMPLETE_CLASS,
  useBounceTextEnterAnimation,
} from 'utils/animations/useBounceTextEnterAnimation';
import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

const useCircularExpansionHeaderBackgroundAnimation = () => {
  const primaryTitleRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});
  const secondaryTitleRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

  const primaryTitleRefValues = Object.values(primaryTitleRefs.current);
  const secondaryTitleRefValues = Object.values(secondaryTitleRefs.current);

  const { containerRef, contentRef } = useMaskCircle([
    ...primaryTitleRefValues,
    ...secondaryTitleRefValues,
  ]);

  useBounceTextEnterAnimation(containerRef, primaryTitleRefValues, {
    onComplete: () => void 0,
  });

  useBounceTextEnterAnimation(containerRef, secondaryTitleRefValues, {
    enterFromRight: true,
    timelineOptions: {
      delay: 1,
      onComplete: () => {
        contentRef.current?.classList.add(ANIMATION_COMPLETE_CLASS);
      },
    },
  });

  const getPrimaryRef: (key: string) => React.LegacyRef<HTMLDivElement> = key => element => {
    if (!primaryTitleRefs.current[key]) {
      primaryTitleRefs.current[key] = { current: element };
    }
  };

  const getSecondaryRef: (key: string) => React.LegacyRef<HTMLDivElement> = key => element => {
    if (!secondaryTitleRefs.current[key]) {
      secondaryTitleRefs.current[key] = { current: element };
    }
  };

  return { containerRef, contentRef, getPrimaryRef, getSecondaryRef };
};

export const useCircularExpansionHeaderBackgroundData = ({
  primary,
}: TCircularExpansionHeaderBackgroundSection) => {
  const {
    solution_circular_expansion_header_background_image: backgroundImage,
    solution_circular_expansion_header_background_primary_title,
    solution_circular_expansion_header_background_secondary_title,
    solution_circular_expansion_header_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const primaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_circular_expansion_header_background_primary_title, '')
      ),
    [solution_circular_expansion_header_background_primary_title]
  );

  const secondaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(solution_circular_expansion_header_background_secondary_title, '')
      ),
    [solution_circular_expansion_header_background_primary_title]
  );

  return {
    primaryTitle,
    secondaryTitle,
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    ...useCircularExpansionHeaderBackgroundAnimation(),
  };
};
