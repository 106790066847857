import { RichTextBlock } from 'prismic-reactjs';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';

import { TQuadBlockCircularExpansionBackgroundSection } from './QuadBlockCircularExpansionBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';
import useMaskCircle from 'utils/animations/useMaskCircle';
import { useBounceTextEnterAnimation } from 'utils/animations/useBounceTextEnterAnimation';

export const useQuadBlockCircularExpansionBackgroundTertiaryContentAnimation = () => {
  const titleRefs = useRef<Record<string, React.RefObject<HTMLDivElement>>>({});

  const titleRefValues = Object.values(titleRefs.current);

  const { containerRef, contentRef } = useMaskCircle(titleRefValues);

  useBounceTextEnterAnimation(containerRef, titleRefValues);

  const getTitleRef: (key: string) => React.LegacyRef<HTMLDivElement> = key => element => {
    if (!titleRefs.current[key]) {
      titleRefs.current[key] = { current: element };
    }
  };

  return { containerRef, contentRef, getTitleRef };
};

export const useQuadBlockCircularExpansionBackgroundData = ({
  primary,
}: TQuadBlockCircularExpansionBackgroundSection) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const context = gsap.context(() => {
      gsap.fromTo(
        backgroundRef.current,
        {
          translateY: '0%',
        },
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: 'top top',
            end: `bottom bottom`,
            scrub: true,
          },
          translateY: '-22.5%',
        }
      );
    });

    return () => context.revert();
  }, []);

  const {
    solution_quad_block_circular_expansion_background_image: backgroundImage,
    solution_quad_block_circular_expansion_background_primary_title: primaryTitle,
    solution_quad_block_circular_expansion_background_primary_description: primaryDescription,
    solution_quad_block_circular_expansion_background_secondary_title,
    solution_quad_block_circular_expansion_background_secondary_description: secondaryDescription,
    solution_quad_block_circular_expansion_background_tertiary_title,
    solution_quad_block_circular_expansion_background_tertiary_description: tertiaryDescription,
    solution_quad_block_circular_expansion_background_quarternary_title,
    solution_quad_block_circular_expansion_background_quarternary_description:
      quarternaryDescription,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  const secondaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(
          solution_quad_block_circular_expansion_background_secondary_title,
          ''
        )
      ),
    [solution_quad_block_circular_expansion_background_secondary_title]
  );

  const tertiaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(
          solution_quad_block_circular_expansion_background_tertiary_title,
          ''
        )
      ),
    [solution_quad_block_circular_expansion_background_tertiary_title]
  );

  const quarternaryTitle: RichTextBlock[][] = useMemo(
    () =>
      splitRichTextByNewLine(
        richTextWithPlaceholder(
          solution_quad_block_circular_expansion_background_quarternary_title,
          ''
        )
      ),
    [solution_quad_block_circular_expansion_background_quarternary_title]
  );

  return {
    primaryTitle: richTextWithPlaceholder(primaryTitle, ''),
    primaryDescription: richTextWithPlaceholder(primaryDescription, ''),
    secondaryTitle,
    secondaryDescription: richTextWithPlaceholder(secondaryDescription, ''),
    tertiaryTitle,
    tertiaryDescription: richTextWithPlaceholder(tertiaryDescription, ''),
    quarternaryTitle,
    quarternaryDescription: richTextWithPlaceholder(quarternaryDescription, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
    backgroundRef,
    containerRef,
  };
};
