import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useTextBaseBackgroundData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TTextBaseBackgroundSection =
  Normalize<Queries.SolutionDesktopTextBaseBackgroundData_PrismicSolutionDataDesktopSolutionTextBaseBackground_Fragment>;

const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  position: relative;
  row-gap: 5rem;
  align-content: flex-start;
  padding: 37.25rem 0 0 14.625rem;
  height: 150rem;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    z-index: 1;

    & > h2 {
      text-align: left;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 11.125rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      strong {
        font-size: 13.75rem;
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    z-index: 1;
    width: fit-content;

    & > p {
      text-align: left;
      line-height: 1.2;
      color: ${colors.white};
      font-size: 2.5rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const TextBaseBackgroundSection: React.FC<TTextBaseBackgroundSection> = sliceData => {
  const { backgroundImage, title, description } = useTextBaseBackgroundData(sliceData);

  return (
    <Container>
      {backgroundImage ? <BackgroundImage {...backgroundImage} /> : null}
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopTextBaseBackgroundFragment = graphql`
  fragment SolutionDesktopTextBaseBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionTextBaseBackground {
      slice_type

      primary {
        solution_text_base_background_image {
          gatsbyImageData
          alt
        }
        solution_text_base_background_title {
          richText
        }
        solution_text_base_background_description {
          richText
        }
      }
    }
  }
`;

export default TextBaseBackgroundSection;
