import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Solution from './Solution';

import { withHead } from 'components/SEO';

export const Head = withHead<Queries.SolutionQuery>('solution');

export const query = graphql`
  query Solution($id: String) {
    solution: prismicSolution(id: { eq: $id }) {
      ...SEOData
      ...SolutionData
      ...SolutionLayoutData
      _previewable
    }
  }
`;

export type AllSolutionsQuery = Queries.AllSolutionsQuery;

export default withPrismicPreview(Solution);
