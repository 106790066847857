import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useDualBlockTextBackgroundData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';

import { Normalize } from 'utils/types';

export type TDualBlockTextSingleBackgroundSection =
  Normalize<Queries.SolutionDesktopDualBlockTextSingleBackgroundData_PrismicSolutionDataDesktopSolutionDualBlockTextSingleBackground_Fragment>;

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 164.375rem 55.875rem;
  align-content: center;
  overflow: hidden;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const DualBlockTextSingleBackgroundSection: React.FC<
  TDualBlockTextSingleBackgroundSection
> = sliceData => {
  const {
    backgroundImage,
    primaryTitle,
    primaryDescription,
    secondaryTitle,
    secondaryDescription,
  } = useDualBlockTextBackgroundData(sliceData);

  return (
    <Container>
      {backgroundImage ? <BackgroundImage {...backgroundImage} /> : null}
      <PrimaryContent
        primaryTitle={primaryTitle}
        primaryDescription={primaryDescription}
        secondaryTitle={secondaryTitle}
      />
      <SecondaryContent description={secondaryDescription} />
    </Container>
  );
};

export const desktopDualBlockTextSingleBackgroundFragment = graphql`
  fragment SolutionDesktopDualBlockTextSingleBackgroundData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionDualBlockTextSingleBackground {
      slice_type

      ...SolutionDesktopDualBlockTextSingleBackgroundPrimaryContentData
      ...SolutionDesktopDualBlockTextSingleBackgroundSecondaryContentData

      primary {
        solution_dual_block_text_single_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default DualBlockTextSingleBackgroundSection;
