import { TCenteredDividerTextSection } from './CenteredDividerTextSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useCenteredDividerTextData = ({ primary }: TCenteredDividerTextSection) => {
  const {
    solution_centered_divider_text_title: title,
    solution_centered_divider_text_description: description,
  } = primary;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
  };
};
