import { TGridImagesSection } from './GridImagesSection';
import { MAX_GRID_IMAGES } from './constants';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useGridImagesData = ({ primary, items }: TGridImagesSection) => {
  const { solution_grid_images_title: title } = primary;

  const gridImages = items
    .reduce((acm, { solution_grid_images_grid_image: image }) => {
      if (!image || !image.gatsbyImageData) return acm;

      const { gatsbyImageData, alt } = image;
      return [...acm, { image: gatsbyImageData, alt: alt ?? '' }];
    }, [])
    .slice(0, MAX_GRID_IMAGES);

  return {
    title: richTextWithPlaceholder(title, ''),
    gridImages,
  };
};
