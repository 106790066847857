import { TCenteredTextSection } from './CenteredTextSection';
import { VARIANT } from './constants';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useCenteredTextData = ({ primary }: TCenteredTextSection) => {
  const {
    solution_centered_text_variant: variant,
    solution_centered_text_description: description,
  } = primary;

  return {
    variant: variant as VARIANT,
    description: richTextWithPlaceholder(description, ''),
  };
};
