import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface ITertiaryContent {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  row-gap: 3.25rem;
  align-content: center;
  justify-content: center;
  justify-items: center;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-align: center;
      line-height: 0.965;
      letter-spacing: -0.04em;
      color: ${colors.white};
      font-size: 8rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;
    background-color: #5b5b5b52;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: ${colors.white};
      width: 11.5rem;
      height: 1px;
      content: '';
    }

    & > p {
      padding: 3rem;
      text-align: center;
      line-height: 1.2;
      color: ${colors.white};
      font-size: 2.75rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const TertiaryContent: React.FC<ITertiaryContent> = ({ title, description }) => {
  return (
    <Container>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopQuadBlockTextBackgroundTertiaryContentFragment = graphql`
  fragment SolutionDesktopQuadBlockTextBackgroundTertiaryContentData on PrismicSolutionDataDesktopSolutionQuadBlockTextBackground {
    primary {
      solution_quad_block_text_background_tertiary_title {
        richText
      }
      solution_quad_block_text_background_tertiary_description {
        richText
      }
    }
  }
`;

export default TertiaryContent;
