import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import { useCenteredDividerTextData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TCenteredDividerTextSection =
  Normalize<Queries.SolutionDesktopCenteredDividerTextData_PrismicSolutionDataDesktopSolutionCenteredDividerText_Fragment>;

const Container = styled.div`
  display: grid;
  position: relative;
  row-gap: 5.5rem;
  align-content: center;
  justify-items: center;
  background-color: #626262;
  padding: 12rem 12rem 9rem;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      display: block;
      margin-top: 8.5rem;
      background-color: ${colors.primary};
      width: 11.5rem;
      height: 0.25rem;
      content: '';
    }

    & > h2 {
      text-align: center;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.primary};
      font-size: 13.75rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      & strong {
        line-height: 1.03;
        letter-spacing: -0.11em;
        font-size: 18.75rem;

        ${tp.useFont(fonts => fonts.Switzer.regular)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;
    justify-content: center;

    & > p {
      text-align: center;
      line-height: 1.1;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const CenteredDividerTextSection: React.FC<TCenteredDividerTextSection> = sliceData => {
  const { title, description } = useCenteredDividerTextData(sliceData);

  return (
    <Container>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopCenteredDividerTextFragment = graphql`
  fragment SolutionDesktopCenteredDividerTextData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionCenteredDividerText {
      slice_type

      primary {
        solution_centered_divider_text_title {
          richText
        }
        solution_centered_divider_text_description {
          richText
        }
      }
    }
  }
`;

export default CenteredDividerTextSection;
