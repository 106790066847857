import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

import { useTextTripleWeightData } from './hooks';

import { RichText } from 'components/Prismic';
import { Normalize } from 'utils/types';

export type TTextTripleWeightSection =
  Normalize<Queries.SolutionDesktopTextTripleWeightData_PrismicSolutionDataDesktopSolutionTextTripleWeight_Fragment>;

const Container = styled.div`
  ${({ theme: { colors } }) => css`
    box-sizing: border-box;
    display: grid;
    position: relative;
    row-gap: 7.25rem;
    align-content: flex-end;
    background-color: #233b47;
    padding-bottom: 11.25rem;
    height: 139.625rem;
    overflow: hidden;

    &::after {
      display: block;
      position: absolute;
      z-index: 2;
      background-color: ${colors.black}40;
      width: 100%;
      height: 100%;
      content: '';
    }
  `}
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    flex-direction: column;
    z-index: 3;

    & > h2 {
      padding-left: 15rem;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white}ED;
      font-size: 11.125rem;

      ${tp.useFont(fonts => fonts.Switzer.lightest)};

      strong {
        font-size: 13.75rem;

        ${tp.useFont(fonts => fonts.Switzer.light)};
      }

      em {
        font-style: normal;
        ${tp.useFont(fonts => fonts.Switzer.light)};
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    position: relative;
    width: fit-content;

    & > p {
      z-index: 3;
      background-color: ${colors.black}25;
      padding: 3rem 2rem 3rem 15rem;
      line-height: 1.12;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const TextTripleWeightSection: React.FC<TTextTripleWeightSection> = sliceData => {
  const { title, description } = useTextTripleWeightData(sliceData);

  return (
    <Container>
      <Title render={title} />
      <Description render={description} />
    </Container>
  );
};

export const desktopTextTripleWeightFragment = graphql`
  fragment SolutionDesktopTextTripleWeightData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionTextTripleWeight {
      slice_type

      primary {
        solution_text_triple_weight_title {
          richText
        }
        solution_text_triple_weight_description {
          richText
        }
      }
    }
  }
`;

export default TextTripleWeightSection;
