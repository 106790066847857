import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { TTextLongBackgroundSection } from './TextLongBackgroundSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useTextLongBackgroundData = ({ primary }: TTextLongBackgroundSection) => {
  const {
    solution_text_long_background_image: backgroundImage,
    solution_text_long_background_title,
    solution_text_long_background_description: description,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;
  const title: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(solution_text_long_background_title, '')),
    [solution_text_long_background_title]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
