import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface ISecondaryContent {
  title: RichTextBlock[];
  description: RichTextBlock[];
}

const Container = styled.div`
  display: grid;
  justify-content: flex-end;
  justify-items: flex-start;
  z-index: 1;
  padding-bottom: 24.125rem;
`;

const Content = styled.div`
  display: grid;
  row-gap: 5rem;
  align-content: flex-end;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;

    & > h2 {
      text-shadow: 0 0.25rem 0.25rem ${colors.black}40;
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: #2a2a2a;
      font-size: 11.125rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      strong {
        color: ${colors.white};
        font-size: 13.75rem;
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    z-index: 1;
    background-color: ${colors.white}CC;

    & > p {
      padding: 2rem 2rem 4rem 1rem;
      line-height: 1.2;
      color: ${colors.black};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ title, description }) => {
  return (
    <Container>
      <Content>
        <Title render={title} />
        <Description render={description} />
      </Content>
    </Container>
  );
};

export const desktopDualBlockHighlightTextBackgroundSecondaryContentFragment = graphql`
  fragment SolutionDesktopDualBlockHighlightTextBackgroundSecondaryContentData on PrismicSolutionDataDesktopSolutionDualBlockHighlightTextBackground {
    primary {
      solution_dual_block_highlight_text_background_secondary_title {
        richText
      }
      solution_dual_block_highlight_text_background_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
