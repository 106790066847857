import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { RichTextBlock } from 'prismic-reactjs';

import { RichText } from 'components/Prismic';

interface ISecondaryContent {
  title: RichTextBlock[];
  description: RichTextBlock[];
  image: Pick<GatsbyImageProps, 'image' | 'alt'> | null;
}

const Container = styled.div`
  display: grid;
  position: relative;
  align-items: flex-end;
  justify-items: flex-end;
  padding: 0 2.25rem 8rem 0;
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: grid;
  row-gap: 6.5rem;
  justify-items: flex-start;
  z-index: 1;
`;

const Title = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    justify-content: center;

    & > h2 {
      line-height: 1.04;
      letter-spacing: -0.1em;
      color: ${colors.white};
      font-size: 11.5625rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};

      strong {
        font-size: 13.75rem;
      }
    }
  `}
`;

const Description = styled(RichText)`
  ${({ theme: { colors, tp } }) => css`
    display: flex;
    justify-content: center;

    & > p {
      line-height: 1.04;
      color: ${colors.white};
      font-size: 3.25rem;

      ${tp.useFont(fonts => fonts.Switzer.light)};
    }
  `}
`;

const SecondaryContent: React.FC<ISecondaryContent> = ({ title, description, image }) => {
  return (
    <Container>
      {image ? <BackgroundImage {...image} /> : null}
      <Content>
        <Title render={title} />
        <Description render={description} />
      </Content>
    </Container>
  );
};

export const desktopDualBlockTextBackgroundSecondaryContentFragment = graphql`
  fragment SolutionDesktopDualBlockTextBackgroundSecondaryContentData on PrismicSolutionDataDesktopSolutionDualBlockTextBackground {
    primary {
      solution_dual_block_text_background_secondary_image {
        gatsbyImageData
        alt
      }
      solution_dual_block_text_background_secondary_title {
        richText
      }
      solution_dual_block_text_background_secondary_description {
        richText
      }
    }
  }
`;

export default SecondaryContent;
