import { THeaderBackgroundSection } from './HeaderBackgroundSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useHeaderBackgroundData = ({ primary }: THeaderBackgroundSection) => {
  const {
    solution_header_background_image: backgroundImage,
    solution_header_background_title: title,
  } = primary;

  const { gatsbyImageData, alt } = backgroundImage;

  return {
    title: richTextWithPlaceholder(title, ''),
    backgroundImage: gatsbyImageData ? { image: gatsbyImageData, alt: alt ?? '' } : null,
  };
};
