import { TDualToneTextSection } from './DualToneTextSection';
import { VARIANT } from './constants';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useDualToneTextData = ({ primary }: TDualToneTextSection) => {
  const {
    solution_dual_tone_text_variant: variant,
    solution_dual_tone_text_title: title,
    solution_dual_tone_text_description: description,
  } = primary;

  return {
    variant: variant as VARIANT,
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
  };
};
