import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useDualBlockEndorsementsData } from './hooks';
import PrimaryContent from './PrimaryContent';
import SecondaryContent from './SecondaryContent';

import { Normalize } from 'utils/types';

export type TDualBlockEndorsementsSection =
  Normalize<Queries.SolutionDesktopDualBlockEndorsementsData_PrismicSolutionDataDesktopSolutionDualBlockEndorsements_Fragment>;

const Container = styled.div`
  display: grid;
  position: relative;
  grid-template-rows: 172.375rem 130.25rem;
  row-gap: 3.25rem;
  align-content: center;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 120%;
`;

const BackgroundImage = styled(GatsbyImage)`
  filter: grayscale(1);
`;

const DualBlockEndorsementsSection: React.FC<TDualBlockEndorsementsSection> = sliceData => {
  const {
    backgroundImage,
    backgroundRef,
    containerRef,
    primaryTitle,
    primaryDescription,
    secondaryTitle,
    secondaryDescription,
    leftIcons,
    rightIcons,
  } = useDualBlockEndorsementsData(sliceData);

  return (
    <Container ref={containerRef}>
      {backgroundImage ? (
        <ImageContainer ref={backgroundRef}>
          <BackgroundImage {...backgroundImage} />
        </ImageContainer>
      ) : null}
      <PrimaryContent title={primaryTitle} description={primaryDescription} />
      <SecondaryContent
        title={secondaryTitle}
        description={secondaryDescription}
        leftIcons={leftIcons}
        rightIcons={rightIcons}
      />
    </Container>
  );
};

export const desktopDualBlockEndorsementsFragment = graphql`
  fragment SolutionDesktopDualBlockEndorsementsData on PrismicSolutionDataDesktopSlicesType {
    ... on PrismicSolutionDataDesktopSolutionDualBlockEndorsements {
      slice_type

      ...SolutionDesktopDualBlockEndorsementsPrimaryContentData
      ...SolutionDesktopDualBlockEndorsementsSecondaryContentData

      primary {
        solution_dual_block_endorsements_background_image {
          gatsbyImageData
          alt
        }
      }
    }
  }
`;

export default DualBlockEndorsementsSection;
