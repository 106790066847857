import { RichTextBlock } from 'prismic-reactjs';
import { useMemo } from 'react';

import { THeaderTextSection } from './HeaderTextSection';

import { richTextWithPlaceholder, splitRichTextByNewLine } from 'utils/prismic';

export const useHeaderTextData = ({ primary }: THeaderTextSection) => {
  const { solution_header_text_title, solution_header_text_description: description } = primary;

  const title: RichTextBlock[][] = useMemo(
    () => splitRichTextByNewLine(richTextWithPlaceholder(solution_header_text_title, '')),
    [solution_header_text_title]
  );

  return {
    title,
    description: richTextWithPlaceholder(description, ''),
  };
};
