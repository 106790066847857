import { TTextTripleWeightSection } from './TextTripleWeightSection';

import { richTextWithPlaceholder } from 'utils/prismic';

export const useTextTripleWeightData = ({ primary }: TTextTripleWeightSection) => {
  const {
    solution_text_triple_weight_title: title,
    solution_text_triple_weight_description: description,
  } = primary;

  return {
    title: richTextWithPlaceholder(title, ''),
    description: richTextWithPlaceholder(description, ''),
  };
};
